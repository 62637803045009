import { tailwind } from "@theme-ui/presets"
import { merge } from "theme-ui"
import defaultTheme from "@lekoarts/gatsby-theme-minimal-blog/src/gatsby-plugin-theme-ui/index";

const headingStyles = {
  h1: {...tailwind.styles.h1, color: `heading`, fontSize: [5, 5, 5], mt: 2},
  h2: {...tailwind.styles.h2, color: `heading`, fontSize: [4, 4, 4], mt: 2},
  h3: {...tailwind.styles.h3, color: `heading`, fontSize: [3, 3, 3], mt: 2},
  h4: {...tailwind.styles.h4, color: `heading`, fontSize: [2, 2, 2]},
  h5: {...tailwind.styles.h4, color: `heading`, fontSize: [1, 1, 1]},
  h6: {...tailwind.styles.h4, color: `heading`, fontSize: 1, mb: 2},
}
const commonInputStyles = {
  py: 2,
  px: 3,
  fontSize: `100%`,
  borderRadius: `default`,
  appearance: `none`,
  lineHeight: `tight`,
}

const palateUrl = `https://coolors.co/f27579-94958f-ee464c-ffeeee-75b8f2`;
const hexMatch = palateUrl.match(/([0-9a-f]{6})/g);

const primaryColor = `#`+hexMatch[0];
const secondaryColor = `#`+hexMatch[1];

const theme = merge(defaultTheme, {
  colors: {
    ...defaultTheme.colors,
    primary: primaryColor,
    secondary: secondaryColor,
    toggleIcon: tailwind.colors.gray[8],
    heading: tailwind.colors.black,
    divide: tailwind.colors.gray[4],
    modes: {
      dark: {
        text: tailwind.colors.gray[4],
        primary: primaryColor,
        secondary: secondaryColor,
        toggleIcon: tailwind.colors.gray[4],
        background: `#1A202C`,
        heading: tailwind.colors.white,
        divide: tailwind.colors.gray[8],
      },
    },
  },
  fonts: {
    body: `'Source Sans Pro', 'APPLE SD Gothic Neo', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
  },
  styles: {
    ...defaultTheme.styles,
    ...headingStyles,
  },
  text: {
    ...defaultTheme.text,
    ...headingStyles,
  },
  underline: {
    ...commonInputStyles,
    backgroundColor: `transparent`,
    border: `none`,
    borderBottomWidth: `8px`,
    borderBottomStyle: `solid`,
    borderBottomColor: `primary`,
    borderRadius: `0px`,
    color: `gray.7`,
    "&:focus": {
      outline: `none`,
      borderColor: `primary`,
      backgroundColor: `white`,
    },
  },
});

export default theme;
